// Color : 
$mainColor: #651106;
$secondColor: #CA2C00;
// $secondColor: #DA7D54;

$white: #fff;
$txtColor: $mainColor;
$black: #333;

$secondColorBack: rgba(217, 141, 98, 0.1);

body {
  background: $secondColorBack;
}

// Layout : 
#main {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.main-home section, .main-stay section, .main-yearPension section, .main-temporaryPension section, .main-contact section {
    margin-top: 5vh;
  
}
.txt-container {

    p {
      color: $mainColor;
      font-size: 1.2rem;
    }
}
.li-container {
  color: $mainColor;
  ul {
    padding-left: 0;
  }
  li {
      font-size: 1.2rem;
      margin: 10px 0;
  }
  i {
      color: $secondColor !important;
      margin-right: 0.5em;
  }
}
.border-container {
    background: $white;
}
.font-title {
  color: $secondColor;
  font-size: 1.6rem;
}

h1 {
  font-size: 2rem !important;
  color: $secondColor !important;
  margin-top: 5vh;
}
h2 {
  color: $secondColor;
}

.nav-item.active a {
  color: $secondColor !important;
}

@media(min-width: 768px) {
  .main-home section, .main-stay section {
      margin-top: 15vh;
  }
  .txt-container {

    p {
      font-size: 1.3rem;
    }
    .blocImageRightTxt {
      padding-right: 15px !important;
    }
  }
  .li-container {

    li {
        margin: 10px 0 10px 2em;
    }
    i {
        margin-right: 1em;
    }
  }

  h1 {
    font-size: 3rem !important;
    margin-top: 0;
  }
  .border-radius-container {
      p {
          padding-left: 2rem;
      }
  }

}
// Global: 
html {
  scroll-behavior: none;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
@media(min-width: 768px) {
  .border-radius-left {
    border-radius: 350px 0 0 0;
  }
  .border-radius-right {
    border-radius: 0 350px 0 0;
  }
  .b-radius {
    border-radius: 15px;
  }
}
.txt-important {
  color: $mainColor;
  text-align: center;
}


// font : 
@font-face {
  font-family: 'Trirong';
  src: url('../fonts/trirong/Trirong-Regular.ttf') format('truetype');
}
@font-face {
   font-family: 'Oregano';
  src: url('../fonts/oregano/Oregano-Regular.ttf') format('truetype');
}
:root {
  --mainFont: 'Trirong', sans-serif;
  --logoFont: 'Oregano', sans-serif;
}


html, body, header, main, footer {
  font-family: var(--mainFont);
}
.fontTitle {
  font-family: var(--logoFont);
}








