
// Bubble : 
.bubble-contact-content {
    color: $secondColor;
        &:hover {
            color: $mainColor;
        }
        i {
            font-size: 2em;
            margin-top: 1em;
        }
        span {
            margin-top: 1em;
            font-size: 1.1rem;
        }
}

// Form : 
#form-contact {
    padding: 2em;
    margin-top: 6vh;

    .container-input input, .container-msg textarea, .btn-submit {
        color: $white;
        border: 0;
        background: $secondColor;
        font-size: 1.1rem;

            &::placeholder {
                color: $white;
                font-style: italic;
            }
    }
        .container-input {

            input {
                margin-top: 1em;
                padding: 0.7em;  
            }
    }
    .btn-submit {
        color: $white;
        padding: 10px 2em;
        border-radius: 8px;
        transition: all .3s;
            &:hover {
                transform: scale(1.05);
            }
    }
    .form-response {
        font-size: 1.2rem;
    }
}

// Banner : 
.banner-contact {
    margin-top: 15vh !important;
    height: 40vh !important;
    background: url(../../../images/contact/contact-banner.jpg);   
    background-size: cover;
    background-attachment: fixed;
}

// Partenaire : 
.partner {

    .partner-content {
        padding: 2em;

        h2 {
            font-size: 1.2rem;
        }
    }

    .container-link-partner {

        a {
            color: $mainColor;
            font-size: 1rem;
            margin-top: 1.2em;
            transition: all .3s;
                &:hover {
                    transform: scale(1.1);
                    transition: all .3s;
                }
        }
        i {
            color: $secondColor;
        }
        span {
            margin-top: 0.5em;
        }
        li {
            color: $mainColor;
        }
    }
}

.container-link-partner-2 {
    
    .span-saddle {
        font-size: 1rem;
    }
    i {
        color: $secondColor;
        font-size: 1.2rem;
    }
    a {
        font-size: 1rem !important;
    } 

}

@media(min-width: 768px) {
    .imgContactTop {
        width: 50%;
    }
    #main-contact {
        padding-top: 10vh !important;
    }
    // Bubble : 
    .bubble-contact-content {
            i {
                font-size: 2em;
            }
            span {
                margin-top: 1em;
                font-size: 1.2rem;
            }
    }
    #form-contact {
        border-radius: 8px;


            input {
                margin: 0 1em;
            }
    }
        .container-msg {
            textarea {
                width: 60%;
            }
    }
    .partner {

        .partner-content {
            padding: 2em;
            border-radius: 8px;

            h2 {
                font-size: 2.2rem;
            }
        }
        .container-link-partner {
    
            a {
                color: $mainColor;
                font-size: 1.3rem;
                margin-top: 1.2em;
            }
            span {
                margin-top: 0.5em;
            }
        }
    
    }
}

