

@media(min-width: 768px) {
    .main-education {

        img {
            width: 50%;
            margin: 0 auto;
            border-radius: 30px;
        }
    }
}
