.main-home {
    margin-bottom: 15vh;

    ul {
        padding-left: 1em !important;       
    }

    .section-1 {
        .txt-section1-container {
            margin: 0;
        }
        h1 {
            color: $mainColor;
            font-size: 5rem;
        }
        h2 {
            color: $secondColor;
            font-size: 2rem;
        }
    }
    
    // Services : 
    .service-container {
        background: rgba(255, 255, 255, 1);    
        padding: 8vh 3vh;
        border-radius: none;

            h1, h3 {
                color: $secondColor;
            }
            a {
                transition: all .3s;
                    &:hover {
                        transform: scale(1.05);
                    }
            }
            img {
                border-radius: 50px;
                margin: 3vh 0;
            }
            h3 {
                font-size: 1.6rem;
            }
    }
    .banner-home-container {
        background: $white;   
        background-size: cover;
        background-attachment: fixed;

            .banner-home-content {
                border-radius: 20px;
                margin: 8vh;
                padding: 6vh 8vh;
                background-color: rgba(255, 255, 255, .4);
            }
            img {
                width: 250px;
            }
            a {
                background: $secondColor;
                padding: 5px 10px;
                font-size: 1.6rem;
                color: $white;
                border-radius: 12px;
                transition: .3s;
                    &:hover {
                        transform: scale(1.05);
                    }
            }
    }
}

// dekstop : 
@media(min-width: 768px) {
    .main-home {

        h2 {
            font-size: 2.5rem;
        }
        .banner-home-container {
            background: url(../../../images/home/banner.JPG);   
        }
        .service-container {
            padding: 10vh 5vh;
            border-radius: 30px;
    
                h1 {
                    color: $mainColor;
                }
                img {
                    margin: 5vh 0 3vh 0;
                }
        }
        .banner-home-container {
            a {
                width: 8em;
            }
        }
    }
}