
.main-pension {
    h1 {
        margin-top: 5vh;
    }
    ul {
        padding-left: 0;
    }
}


@media(min-width: 768px) {
    .main-pension {
        h1 {
            margin-top: 10vh;
        }
    }
}