.price-table-content {
    color: $mainColor;
    border-radius: 20px;
    margin: 0;
    padding: 0;

        .price-table-header  {
            background: $secondColor;
            border-radius: 20px 20px 0 0 ;
            padding: 10px;

                h2 {
                    color: $white;
                }
        }
        .price-table-body {
            border: 1.5px solid $secondColor;
            border-radius: 0 0 5px 5px;

            h3 {
                font-size: 1.2rem;
            }
            span {
                color: $mainColor;
                font-size: 1.2rem;
            }
            img {
                width: 280px;
            }
            .separator {
                width: 30%;
                height: 2px;
                background: $secondColor;
            }

        }




}