#header {

    .logo-header {
      width: 150px;
      display: none;
    }
    .btn-nav {
        color: $mainColor;
          &:hover, .active a {
            color: $secondColor;
          }
      } 
}

@media (min-width: 768px) {

  #header {
    padding-right: 15vh;
    padding-left: 5vh;
    
    .logo-header {
        display: block;
    }
    .btn-nav {
      font-size: 1.5rem;
    }

  }

}