#main-footer {
    color: $mainColor;

    .line-footer {
        height: 1px;
        background: $secondColor;
        width: 70%;
    }
    .logo {
        width: 180px;
    }
    p, a {
        margin-bottom: 5px;
        font-size: 1rem;
    }
    a {
        color: $secondColor;
            &:hover {
                color: $mainColor;
            }
            span {
                margin: 0 8px;
            }
    }
}

@media(min-width: 768px) {
    #main-footer {
        margin-top: 0em;
        padding: 2em 0;

        .line-footer {
            width: 50%;
        }
        p, a {
            font-size: 1rem;
        }
    }
}
